import type { Notification } from '../type';

const filterUnreadNotifications = (notifications: Notification[], lastReadTime: Date | null): Notification[] => {
  // タイムスタンプがない（=初めてサイトを訪れる）人はすべてのお知らせが未読なので、
  // 取得したお知らせがそのまま未読お知らせになる
  if (lastReadTime === null) return notifications;
  const unreadNotifications = filterUnread(notifications, lastReadTime.getTime());

  return unreadNotifications;
};

// お知らせ一覧とtime stampから未読お知らせを取得する
const filterUnread = (notifications: Notification[], timeStamp: number): Notification[] => {
  const unreads: Notification[] = notifications.filter((notification) => {
    const activatedTime = new Date(notification.activatedDate).getTime();
    // 最後に見た時よりあとにactivateされている＝まだ見ていないお知らせをfilterする
    return activatedTime > timeStamp;
  });
  return unreads;
};

export default filterUnreadNotifications;
