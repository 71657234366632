import * as React from 'react';

import type { Notification } from 'components/notification/type';
import filterUnreadNotifications from 'components/notification/libs/filterUnreadNotifications';
import NotificationIcon from './components/NotificationIcon';

/*
  HeaderNotificationにまつわる業務ロジックの知識をもつコンポーネント
    - モーダルにどのお知らせを表示するのか
    - 未読のお知らせはどれか
    - バッジにどのようなテキストを表示するのか
    - モーダルを操作したときにどんな処理をするべきか
  を知っている
  ストーリーは書く
*/

type Props = {
  notifications: Notification[];
  lastReadTime: Date | null;
  updateLastReadTime: (date: Date) => void;
};

const HeaderNotificationManager: React.FC<Props> = React.memo(({ notifications, lastReadTime, updateLastReadTime }) => {
  const [badgeText, setBadgeText] = React.useState<string | null>(null);
  const [unreadNotifications, setUnreadNotifications] = React.useState<Notification[]>([]);

  // 直近のお知らせ（モーダルに表示するお知らせ）を切り取る
  const recentNotifications = notifications.slice(0, 4);

  // notificationsが変更されたときだけ以下の処理を行う
  // マウント後にAPIからお知らせを取得したときに主に行う
  React.useEffect(() => {
    // 未読お知らせを取得する
    const unreadNotifications = filterUnreadNotifications(notifications, lastReadTime);
    setUnreadNotifications(unreadNotifications);

    // バッジに表示するテキストを計算する
    const computeBadgeText = (num: number) => {
      // `/notifications`ページのときは、未読件数は0になり、nullを返しbadgeを表示しない
      const isNotificationPage = location.pathname === '/notifications';
      if (isNotificationPage) return null;

      // 未読件数が100件以上なら'+99'と表示する
      if (num > 99) return '+99';

      // 未読件数が0のときはnullを返しbadgeを表示しない
      if (num === 0) return null;

      // それ以外のときは未読件数をそのまま表示する
      return num.toString();
    };
    setBadgeText(computeBadgeText(unreadNotifications.length));

    /*
        本来は`lastReadTime`にも依存するが、ここで使用したいのは
        `notifications` が更新されたときの `lastReadTime` の値だけなので
        依存リストには含めない。`notifications` が更新されず
        `lastReadTime` だけが更新されたときには特に行う処理はない。
        必要な処理は`onModalOpen`で行う。
      */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  /*
      モーダルを開いた時に実行する関数
        - アイコンのバッジを消す
        - 未読お知らせを改めて計算する
        - local storageを更新する
        - lastReadTimeを更新する
      ※ モーダルクリック時に未読お知らせとlastReadTimeを更新することでlastReadTime更新前の未読お知らせを表示できる
    */
  const onOpenModal = React.useCallback(() => {
    const unreadNotifications = filterUnreadNotifications(notifications, lastReadTime);
    setBadgeText(null);
    setUnreadNotifications(unreadNotifications);
    updateLastReadTime(new Date());
  }, [notifications, lastReadTime, updateLastReadTime]);

  return <NotificationIcon recentNotifications={recentNotifications} unreadNotifications={unreadNotifications} badgeText={badgeText} onOpenModal={onOpenModal} />;
});

HeaderNotificationManager.displayName = 'HeaderNotificationManager';

export default HeaderNotificationManager;
