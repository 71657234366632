import * as React from 'react';

import { genPublicUrl } from 'libs/cloudinary';
import Spacer from 'components/atoms/Spacer';
import LazyImage from 'components/atoms/LazyImage';
import NotificationCard from 'components/notification/components/NotificationCard';
import type { Notification } from 'components/notification/type';

import cs from './NotificationCardList.scss';
import OutsideClickHandler from 'components/atoms/OutsideClickHandler';

type Props = {
  notifications: Notification[];
  unreadNotifications: Notification[];
  closeModal: () => void;
};

const NotificationCardList: React.FC<Props> = React.memo(({ notifications, unreadNotifications, closeModal }) => (
  <OutsideClickHandler onClickOutside={closeModal}>
    <div className={cs['container']}>
      <div className={cs['title']}>あなたへのお知らせ</div>
      <Spacer size={28} />
      {notifications.length !== 0 ? (
        <React.Fragment>
          {notifications.map((notification: Notification) => (
            <React.Fragment key={`${notification.notificationType}-${notification.id}`}>
              <NotificationCard notification={notification} isUnread={unreadNotifications.includes(notification)} datePosition="right-edge" />
              <Spacer size={28} />
            </React.Fragment>
          ))}

          <a href="/notifications" className={cs['link']}>
            <div className={cs['check-notifications']}>お知らせ一覧を確認する</div>
          </a>
        </React.Fragment>
      ) : (
        <EmptyMessage />
      )}
    </div>
  </OutsideClickHandler>
));

NotificationCardList.displayName = 'NotificationCardList';

const EmptyMessage: React.FC = React.memo(() => {
  const imageUrl = 'https://s3.ap-northeast-1.amazonaws.com/gracia.tanp/illust/empty/kanozyo.png';

  return (
    <div className={cs['empty-message']}>
      <Spacer size={98} />
      <div className={cs['image-container']}>
        <LazyImage src={genPublicUrl(imageUrl, 108 * 2)} width="108px" height="108px" fit="cover" />
      </div>
      <Spacer size={24} />
      <div className={cs['no-notification']}>まだお知らせはありません</div>
      <Spacer size={16} />
      <div className={cs['description']}>
        お得なクーポンや大切な方の記念日、
        <br />
        タンプからの重要なお知らせが表示されます。
      </div>
      <Spacer size={152} />
    </div>
  );
});

EmptyMessage.displayName = 'EmptyMessage';

export default NotificationCardList;
