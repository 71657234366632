/*
  local storageの操作を行うメソッド

  local storageには最後にお知らせを読んだ時間をタイムスタンプとして保存する
  keyは`last_read_at`にする
  `last_read_at`は1970/01/01/00:00:00から経過したミリ秒を文字列化したもの
*/

export const localStorageKey = 'last_read_at';

export const getFromLocalStorage = (): Date | null => {
  const last_read_at = localStorage.getItem(localStorageKey);
  if (last_read_at === null) return null;
  return new Date(Number(last_read_at));
};

export const updateLocalStorage = (date: Date): void => localStorage.setItem(localStorageKey, date.getTime().toString());
