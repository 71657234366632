import * as React from 'react';

import type { Notification } from 'components/notification/type';
import NotificationCardList from './NotificationCardList';
import IconButton from './IconButton';
import cs from './NotificationIcon.scss';

type Props = {
  recentNotifications: Notification[];
  unreadNotifications: Notification[];
  badgeText: string | null;
  onOpenModal: () => void;
};

const NotificationIcon: React.FC<Props> = React.memo(({ recentNotifications, unreadNotifications, badgeText, onOpenModal }) => {
  const [modal, setModal] = React.useState<boolean>(false);
  const toggleModal = React.useCallback(() => {
    // モーダルを開くときの処理
    if (!modal) onOpenModal();
    setModal((prev) => !prev);
  }, [onOpenModal, modal]);
  const closeModal = React.useCallback(() => {
    setModal(false);
  }, []);

  return (
    <div className={cs['notification']}>
      <IconButton badgeText={badgeText} onClick={toggleModal} />
      {modal ? <NotificationCardList notifications={recentNotifications} unreadNotifications={unreadNotifications} closeModal={closeModal} /> : null}
    </div>
  );
});

NotificationIcon.displayName = 'NotificationIcon';

export default NotificationIcon;
