import * as React from 'react';

import { getFromLocalStorage, updateLocalStorage } from 'components/notification/libs/localStorageService';
import useNotifications from 'components/notification/libs/useNotifications';
import HeaderNotificationManager from './HeaderNotificationManager';

/*
  HeaderNotificationにまつわるContainer Component
  APIによるお知らせの取得とlocal storageの読み取りと更新を行う
  ストーリーは書かない
*/

const HeaderNotificationContainer: React.FC = () => {
  // 一度に取得するお知らせの件数
  const limit = 100;
  const notifications = useNotifications(limit);
  const [lastReadTime, setLastReadTime] = React.useState<Date | null>(getFromLocalStorage);

  // lastReadTimeとlocal storageを更新する
  // lastReadTimeとtime stampの値をsyncさせる
  const updateLastReadTime = React.useCallback((date: Date) => {
    updateLocalStorage(date);
    setLastReadTime(date);
  }, []);

  return <HeaderNotificationManager notifications={notifications} lastReadTime={lastReadTime} updateLastReadTime={updateLastReadTime} />;
};

export default HeaderNotificationContainer;
