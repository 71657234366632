import * as React from 'react';

import * as colors from 'libs/colors';
import computeFromNow from 'libs/computeFromNow';
import { genPublicUrl } from 'libs/cloudinary';
import { merge } from 'libs/classname';
import LazyImage from 'components/atoms/LazyImage';
import Spacer from 'components/atoms/Spacer';
import { ClockIcon } from 'components/icons';
import type { Notification } from 'components/notification/type';

import cs from './NotificationCard.scss';

type Props = {
  notification: Notification;
  isUnread: boolean;
  datePosition: 'right-edge' | 'left' | 'right';
};

const NotificationCard: React.FC<Props> = React.memo(({ notification, isUnread, datePosition }) => {
  const datePositionCSS = React.useMemo(() => {
    if (datePosition === 'right') return cs['right'];
    if (datePosition === 'left') return cs['left'];
    return cs['right-edge'];
  }, [datePosition]);

  return (
    <a href={notification.pageUrl} className={cs['page-url']}>
      <div className={cs['notification']}>
        {isUnread ? <div className={cs['badge']}></div> : null}
        <div className={cs['image-container']}>
          <LazyImage src={genPublicUrl(notification.imageUrl, 112)} width="56px" height="56px" fit="contain" />
        </div>
        <Spacer size={16} />
        <div className={cs['info']}>
          <div className={cs['info-header']}>
            <div className={cs['label']} style={{ ['--bg-color' as any]: notification.labelColor }}>
              {notification.label}
            </div>
            <div className={merge(cs['date'], datePositionCSS)}>
              <ClockIcon color={colors.gray50} size="12px" />
              <Spacer size={4} />
              <div className={cs['date-from-now']}>{computeFromNow(new Date(notification.activatedDate), Date.now())}</div>
            </div>
          </div>
          <Spacer size={8} />
          <p className={cs['content']}>{notification.content}</p>
        </div>
      </div>
    </a>
  );
});

NotificationCard.displayName = 'NotificationCard';

export default NotificationCard;
